import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message };
  }


  render() {
    if (this.state.hasError) {
      return (
        <div className="error">
          <div className="icon">
            <i className="material-icons">error</i>
          </div>
          <ul>
            <li>
              {this.state.error}
            </li>
          </ul>
        </div>
      );
    }

    return this.props.children;
  }
}
